<template>
	<div class="favourites-plug">
		<div class="favourites-plug__content">
			<div class="favourites-plug__img">
				<img src="~@/assets/images/favourites-plug-img.svg" alt="" />
			</div>
			<p class="favourites-plug__text">Add the first offer to your favorites</p>
			<or-link
				:to="{
					name: 'Search',
					params: { lang: $store.state.uiLanguage }
				}"
				:height="48"
				class="w-125"
			>
				Add +
			</or-link>
		</div>
	</div>
</template>

<script>
import OrLink from '@/components/global/orLink.vue'

export default {
	name: 'FavouritesPlug',
	components: { OrLink }
}
</script>

<style lang="scss">
.favourites-plug {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 110px;
	&__content {
		max-width: 165px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__img {
		margin-bottom: 40px;
		width: 99px;
		height: 99px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__text {
		margin: 0 0 30px 0;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		color: #000000;
	}

	&__btn {
		padding: 12px 40px;
	}
}
</style>
