<template>
	<transition-group name="fade" mode="out-in">
		<Common
			type="favorite"
			@cardClick="redirect"
			@getData="addPages"
			:list="favouritesList"
			:isLoading="isLoading"
			:meta="meta"
			:isLike="true"
		/>
		<FavouritesPlug v-if="!isLoading && !favouritesList.length" />
	</transition-group>
</template>

<script>
import { i18n } from '@/i18n'
import { setCommonMetaData } from '@/utils/setMetaData'
import Common from '@/modules/list/Common.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import FavouritesPlug from '@/components/FavouritesPlug.vue'

export default {
	name: 'Favourites',
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.favorites')} - OWRealty`, {
			description: i18n.global.t('meta.favoritesDesc'),
			type: 'website',
			title: i18n.global.t('meta.favoritesTitle'),
			url: window.location.href
		})
	},
	components: { FavouritesPlug, Common },
	data() {
		return {
			isLoading: false,
			meta: {},
			favouritesList: [],
			page: 0,
			per_page: 12
		}
	},
	computed: {
		slugStr() {
			return this.$store.state.favoriteList
				.slice(this.per_page * (this.page - 1), this.per_page * this.page || 1)
				.join(',')
		},
		lastPage() {
			// Делим общее количество объектов на количество объектов на странице и округляем в большую сторону
			return Math.ceil(this.$store.state.favoriteList.length / this.per_page)
		}
	},
	methods: {
		redirect(event, payload) {
			if (
				event.target.type !== 'button' &&
				event.target.className !== 'icon icon-like-full'
			)
				this.$router.push({
					name: 'SoloAds',
					params: { slug: payload, lang: this.$store.state.uiLanguage }
				})
		},
		addPages() {
			this.page += 1
			createRequest(requestConfigs.GETSearch, {
				queryPayload: {
					slugs: this.slugStr
				}
			})
				.then((result) => {
					this.favouritesList.push(...result.response.items)
					this.meta = {
						...result.response.meta,
						current_page: this.page,
						last_page: this.lastPage
					}
				})
				.finally(() => (this.isLoading = false))
		}
	},
	beforeMount() {
		this.isLoading = true
		this.addPages()
	}
}
</script>
